import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import { Image } from "react-datocms"
import colors from "@constants/colors"
import { columns, columns14, columnsInMaxWidth } from "@constants/layout"
import { body, bodyBold, h6 } from "@constants/typography"
import Button from "@components/buttons/Button"
import Scrollbar, {
  Container as ScrollbarContainer,
} from "@components/Scrollbar"
import { DatoResponsiveImage } from "@dato/types"
import { getProductUrl } from "@utils/urls"

const Container = styled.div<any>`
  flex-direction: column;
  display: flex;
  width: 100%;

  ${ScrollbarContainer} {
    padding: 0 20px;
  }

  ${up("laptop")} {
    ${ScrollbarContainer} {
      padding: 0 ${columns14(1)};
    }
  }

  ${up("maxWidth")} {
    ${ScrollbarContainer} {
      padding: 0 ${columns(2)};
    }
  }
`

const Row = styled.div<{
  buttonColor?: string
  buttonTextColor?: string
}>`
  ${({ buttonColor, buttonTextColor }) => css`
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 32px;
    padding: 0 20px;

    ${up("laptop")} {
      margin-bottom: 26px;
      padding: 0 ${columns14(1)};
    }

    ${up("maxWidth")} {
      padding: 0 ${columns(2)};
    }

    ${Button} {
      padding: 0 20px;
      background-color: ${buttonColor ? `#${buttonColor}` : colors.primary};
      color: ${buttonTextColor ? `#${buttonTextColor}` : colors.snow};
    }

    ${up("laptop")} {
      ${Button} {
        width: auto;
      }
    }
  `}
`

const Header = styled.div`
  ${h6}
  flex-shrink: 0;
  display: flex;
  width: 70%;

  ${up("laptop")} {
    width: initial;
    font-size: 22px;
    line-height: 65px;
  }
`

const Content = styled.div`
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: -20px;
  padding: 0 20px 52px;

  ${up("laptop")} {
    padding: 0 ${columns14(1)} 98px;
  }

  ${up("maxWidth")} {
    padding: 0 ${columns(2)} 98px;
  }
`

const Block = styled.div``

const CardWrapper = styled.a<{ clickable?: boolean }>`
  ${({ clickable }) => css`
    flex-direction: row;
    flex-shrink: 0;
    display: inline-flex;
    height: 100%;
    ${clickable ? "pointer-events: all;" : "cursor: default;"}
  `}
`

const Card = styled.div`
  flex-direction: column;
  display: flex;
  width: 68vw;
  border: solid 1px ${colors.divider};
  background-color: ${colors.divider};

  ${up("laptop")} {
    width: ${columns14(3.75)};
  }

  ${up("maxWidth")} {
    width: calc(${columnsInMaxWidth(3.75)});
  }
`

const ImageWrapper = styled.div`
  height: 68vw;
  background: #fff;

  ${up("laptop")} {
    height: ${columns14(3.75)};
  }

  ${up("maxWidth")} {
    height: calc(${columnsInMaxWidth(3.75)});
  }

  .DatoImage {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
`

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.divider};
`

const Body = styled.div`
  padding: 20px;

  ${up("laptop")} {
    padding: 24px 20px 32px;
  }
`

const TextBold = styled.div`
  ${bodyBold}
  margin-bottom: 8px;
  color: ${colors.dark};
`

const Text = styled.div`
  ${body}
  color: ${colors.dark};
`

const Spacer = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 100%;
  background: transparent;

  ${up("laptop")} {
    width: 20px;
  }
`

const FinalSpacer = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 100%;
  background: transparent;

  ${up("laptop")} {
    width: ${columns14(1)};
  }

  ${up("maxWidth")} {
    width: ${columns(2)};
  }
`

// mobile Safari PLEASE
const MobileSafariScrollbarHider = styled.div`
  z-index: 10;
  width: 100%;
  height: 20px;
  background-color: ${colors.snow};
`

interface ProductProps {
  slug?: string
  productName: string
  price: string
  isDiscounted?: string
  discountedPrice?: string
  thumbnail?: DatoResponsiveImage
}

interface ProductSliderProps {
  header: string
  ctaLink?: string
  ctaButtonText?: string
  ctaButtonColor?: string
  ctaButtonTextColor?: string
  products: Array<ProductProps>
}

const ProductSlider: React.FC<ProductSliderProps> = ({
  header,
  ctaLink,
  ctaButtonText,
  ctaButtonColor,
  ctaButtonTextColor,
  products,
}) => {
  const contentRef = React.createRef<HTMLDivElement>()
  console.log(products)

  return (
    <Container>
      <Row buttonColor={ctaButtonColor} buttonTextColor={ctaButtonTextColor}>
        <Header>{header}</Header>
        {ctaLink && ctaButtonText ? (
          ctaLink.includes("tmiproducts.com") ? (
            <Link href={ctaLink ? ctaLink : "#"} passHref>
              <Button>{ctaButtonText}</Button>
            </Link>
          ) : (
            <a target="_blank" href={ctaLink} rel="noopener noreferrer">
              <Button>{ctaButtonText}</Button>
            </a>
          )
        ) : (
          ""
        )}
      </Row>
      <Content ref={contentRef}>
        {products.map((product, i) => (
          <Link
            key={`product-${i}`}
            href={getProductUrl(product.slug ? product.slug : "#")}
            passHref
          >
            <CardWrapper clickable={!!product.slug}>
              <Card>
                <ImageWrapper>
                  {product.thumbnail ? (
                    <Image data={product.thumbnail} className="DatoImage" />
                  ) : (
                    <Placeholder />
                  )}
                </ImageWrapper>
                <Body>
                  <TextBold>{product.productName}</TextBold>
                  <Text>
                    $
                    {(product.isDiscounted == "yes" && product.discountedPrice
                      ? product.discountedPrice
                      : product.price
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </Body>
              </Card>
              {i === products.length - 1 ? <FinalSpacer /> : <Spacer />}
            </CardWrapper>
          </Link>
        ))}
        <FinalSpacer />
      </Content>
      <Scrollbar containerRef={contentRef} />
      <MobileSafariScrollbarHider />
    </Container>
  )
}

export default ProductSlider
