import { createModel } from "@rematch/core"
import { RootModel } from "./index"
import DefaultClient from "apollo-boost"
import createShopifyClient from "@shopify/createApolloClient"
import { GetCheckout } from "@shopify/graphql/queries"
import {
  CreateCheckout,
  UpdateCheckout,
  ApplyDiscount,
  RemoveDiscount,
} from "@shopify/graphql/mutations"
import { UserError, LineItem, CheckoutData } from "@shopify/types"
import createDatoClient from "@dato/createApolloClient"
import { GetCheckoutCartItemData } from "@dato/graphql/queries"
import {
  ToastProduct,
  CartProduct,
  CartProducts,
  CartItem,
  ProductMap,
  LocalCheckout,
} from "../types"
import {
  graphQLRequest,
  getMissingCartProductIds,
  formatDatoCartProductsData,
  getLocalCheckoutProps,
  buildLocalCheckout,
  createCheckoutLineItem,
  getLineItemFromCheckoutData,
  partitionLineItemsByVariantId,
} from "../utils"
import { getIn, isNilOrEmpty } from "@utils/index"
import { formatCheckoutUrl } from "@utils/urls"
import { getLocalStorage, writeLocalStorage } from "@utils/storage"
import { CHECKOUT_KEY, DISCOUNT_KEY } from "@constants/storageKeys"
import * as R from "ramda"

const CHECKOUT_NOTE = "Checkout created via TMI Storefront"

export interface CheckoutState {
  loadingCheckoutData: boolean
  checkoutDataFetched: boolean
  checkoutId: string | null
  data: CheckoutData
  localCheckout: LocalCheckout
  cartProducts: CartProducts
  toastProduct: ToastProduct | null
  showErrorMessage: boolean
  reconciliationCount: number
}

const checkout = createModel<RootModel>()({
  name: "checkout",
  state: {
    loadingCheckoutData: false,
    checkoutDataFetched: false,
    checkoutId: getLocalStorage(CHECKOUT_KEY),
    data: {} as CheckoutData,
    localCheckout: {} as LocalCheckout,
    cartProducts: {} as CartProducts,
    toastProduct: null,
    showErrorMessage: false, // Something went wrong. Your cart has been adjusted.
    reconciliationCount: 0,
  } as CheckoutState,
  // https://github.com/rematch/rematch/issues/816
  // @ts-ignore
  selectors: (slice) => ({
    quantity() {
      return slice((checkout) => {
        if (!checkout?.localCheckout?.items) {
          return 0
        }

        return checkout.localCheckout.items.reduce((total, item) => {
          return total + item.quantity
        }, 0)
      })
    },

    checkoutLink() {
      return slice((checkout) => {
        const checkoutLink = getIn(checkout, "data.webUrl")
        return checkoutLink ? formatCheckoutUrl(checkoutLink) : undefined
      })
    },
  }),
  reducers: {
    setLoadingCheckoutData(state: CheckoutState, loadingCheckoutData: boolean) {
      return {
        ...state,
        loadingCheckoutData,
      }
    },

    setCheckoutDataFetched(state: CheckoutState, checkoutDataFetched: boolean) {
      return {
        ...state,
        checkoutDataFetched,
      }
    },

    clearCheckout(state: CheckoutState) {
      return {
        ...state,
        checkoutId: null,
        data: {} as CheckoutData,
        localCheckout: {} as LocalCheckout,
      }
    },

    updateCheckout(state: CheckoutState, data: CheckoutData) {
      return {
        ...state,
        checkoutId: data.id,
        data,
      }
    },

    updateLocalCheckout(state: CheckoutState, localCheckout: LocalCheckout) {
      return {
        ...state,
        localCheckout,
      }
    },

    updateLocalCheckoutItems(state: CheckoutState, items: Array<CartItem>) {
      return {
        ...state,
        localCheckout: {
          ...state.localCheckout,
          items,
        },
      }
    },

    updateLocalCheckoutProps(state: CheckoutState, localCheckoutProps) {
      return {
        ...state,
        localCheckout: {
          ...localCheckoutProps,
          items: state.localCheckout.items,
        },
      }
    },

    updateCartProducts(state: CheckoutState, data: CartProducts) {
      return {
        ...state,
        cartProducts: {
          ...state.cartProducts,
          ...data,
        },
      }
    },

    updateToastProduct(
      state: CheckoutState,
      toastProduct: ToastProduct | null
    ) {
      return {
        ...state,
        toastProduct: toastProduct,
      }
    },

    // -> Cart HOC
    setShowErrorMessage(state: CheckoutState, showErrorMessage: boolean) {
      return {
        ...state,
        showErrorMessage,
      }
    },

    setReconciliationCount(state: CheckoutState, reconciliationCount: number) {
      return {
        ...state,
        reconciliationCount,
      }
    },
  },
  effects: (dispatch: any) => ({
    setCheckoutId(checkoutId) {
      writeLocalStorage(CHECKOUT_KEY, checkoutId)
    },

    clearCheckout() {
      writeLocalStorage(CHECKOUT_KEY, null)
      writeLocalStorage(DISCOUNT_KEY, null)
    },

    async removeInvalidLineItems(
      {
        lineItems,
        cartProducts,
      }: {
        lineItems: Array<{ node: LineItem }>
        cartProducts: CartProducts
      },
      rootState
    ) {
      const cleanedLineItems = lineItems
        .filter(({ node: lineItem }) => !!cartProducts[lineItem.variant.sku])
        .map(({ node: lineItem }) => ({
          variantId: lineItem.variant.id,
          quantity: 0,
        }))

      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: UpdateCheckout,
        variables: {
          checkoutId: rootState.checkout.checkoutId,
          lineItems: cleanedLineItems,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      const checkoutErrors: Array<UserError> = getIn(
        data,
        "checkoutLineItemsReplace.userErrors",
        []
      )

      if (errors || (checkoutErrors && checkoutErrors.length)) {
        dispatch.checkout.reconcileCheckout()
        return
      }

      const checkout: CheckoutData = getIn(
        data,
        "checkoutLineItemsReplace.checkout"
      )

      dispatch.checkout.updateCheckout(checkout)
      dispatch.checkout.updateLocalCheckoutProps(
        getLocalCheckoutProps(checkout)
      )
    },

    async fetchDatoCartProducts({
      datoProductIds,
      productMap,
    }: {
      datoProductIds: Array<string>
      productMap: ProductMap
    }) {
      const client: DefaultClient<any> = createDatoClient()
      const { data, errors } = await graphQLRequest(client.query, {
        query: GetCheckoutCartItemData,
        variables: { ids: datoProductIds },
      })

      if (errors) {
        return {}
      }

      const formattedData = formatDatoCartProductsData(data, productMap)
      return formattedData
    },

    async getLocalCheckout({
      checkout,
      cartProducts,
    }: {
      checkout: CheckoutData
      cartProducts: CartProducts
    }) {
      const lineItems = getIn(checkout, "lineItems.edges", []) as Array<{
        node: LineItem
      }>

      if (!lineItems.length) {
        dispatch.checkout.updateLocalCheckout({} as LocalCheckout)
        return
      }

      const missingCartProducts = getMissingCartProductIds(
        checkout,
        cartProducts
      )
      let updatedCartProducts = cartProducts

      if (missingCartProducts.length) {
        const missingCartProductsIds = missingCartProducts.map(
          ({ datoProductId }) => datoProductId
        )
        const productMap = missingCartProducts.reduce(
          (productMapResult, { datoProductId, variantSku }) => {
            productMapResult[datoProductId] = variantSku
            return productMapResult as ProductMap
          },
          {}
        )
        const formattedData = await dispatch.checkout.fetchDatoCartProducts({
          datoProductIds: missingCartProductsIds,
          productMap,
        })

        updatedCartProducts = {
          ...updatedCartProducts,
          ...formattedData,
        }

        dispatch.checkout.updateCartProducts(updatedCartProducts)

        if (
          missingCartProductsIds.length !== Object.keys(formattedData).length
        ) {
          dispatch.checkout.removeInvalidLineItems({
            lineItems,
            cartProducts: updatedCartProducts,
          })
        }
      }

      return buildLocalCheckout(checkout, updatedCartProducts)
    },

    // Build localCheckout from latest Checkout data
    async reconcileCheckout(_, rootState) {
      if (rootState.checkout.reconciliationCount > 5) {
        dispatch.checkout.clearCheckout()
        dispatch.checkout.setReconciliationCount(0)
      } else {
        dispatch.checkout.setReconciliationCount(
          rootState.checkout.reconciliationCount + 1
        )
      }

      const localCheckout = await dispatch.checkout.getLocalCheckout({
        checkout: rootState.checkout.data,
        cartProducts: rootState.checkout.cartProducts,
      })

      dispatch.checkout.updateLocalCheckout(localCheckout)
    },

    async fetchCheckout(checkoutId, rootState) {
      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.query, {
        query: GetCheckout,
        variables: {
          checkoutId,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      if (errors) {
        dispatch.checkout.clearCheckout()
        dispatch.checkout.setCheckoutDataFetched(true)
        return
      }

      const checkout: CheckoutData = getIn(data, "node", undefined)
      const lineItems = getIn(checkout, "lineItems.edges", [])
      const invalidLineItem =
        !R.isEmpty(lineItems) &&
        R.find((item: { node: LineItem }) => isNilOrEmpty(item.node.variant))(
          checkout.lineItems.edges
        )

      if (checkout && !checkout.completedAt && !invalidLineItem) {
        dispatch.checkout.setCheckoutId(checkout.id)
        dispatch.checkout.updateCheckout(checkout)

        const localCheckout = await dispatch.checkout.getLocalCheckout({
          checkout,
          cartProducts: rootState.checkout.cartProducts,
        })

        dispatch.checkout.updateLocalCheckout(localCheckout)
      } else {
        //Fire GTM event
        if (checkout?.completedAt) {
          const getDataLayer = () => {
            const _window = typeof window !== "undefined" && (window as any)
            if (_window) {
              return _window.dataLayer || []
            }
          }

          const dataLayer = getDataLayer()

          if (dataLayer) {
            const formattedItems = checkout.lineItems.edges.map((item) => {
              return {
                name: item.node.title,
                price: item.node.customAttributes.find(
                  (element) => element.key == "_productPrice"
                )?.value,
                category: item.node.customAttributes.find(
                  (element) => element.key == "_productCategory"
                )?.value,
                subcategory: item.node.customAttributes.find(
                  (element) => element.key == "_productSubCategory"
                )?.value,
                seatcategory: item.node.customAttributes.find(
                  (element) => element.key == "_productSeatCategory"
                )?.value,
                quantity: item.node.quantity,
                variant: item.node.variant.sku,
              }
            })
            const ga4items = checkout.lineItems.edges.map((item) => {
              return {
                item_name: item.node.title,
                price: item.node.customAttributes.find(
                  (element) => element.key == "_productPrice"
                )?.value,
                item_category: item.node.customAttributes.find(
                  (element) => element.key == "_productCategory"
                )?.value,
                item_category2: item.node.customAttributes.find(
                  (element) => element.key == "_productSubCategory"
                )?.value,
                item_category3: item.node.customAttributes.find(
                  (element) => element.key == "_productSeatCategory"
                )?.value,
                quantity: item.node.quantity,
                item_id: item.node.variant.sku,
              }
            })

            dataLayer.push({
              event: "checkout",
              transactionId: checkout.id,
              transactionAffiliation: "TMI Website Checkout",
              transactionTotal: checkout?.totalPriceV2?.amount,
              transactionTax: checkout?.totalTaxV2?.amount,
              transactionShipping: checkout?.shippingLine?.priceV2?.amount,
              transactionProducts: formattedItems,
            })

            dataLayer.push({
              event: "transactionComplete",
              u1: checkout?.shippingLine?.priceV2?.amount,
              u2: checkout?.totalTaxV2?.amount,
              u3: checkout?.order?.shippingAddress?.country,
              u4: checkout?.order?.shippingAddress?.province,
              u5: checkout?.order?.shippingAddress?.zip,
              u6: checkout?.subtotalPriceV2?.amount,
              u13: formattedItems,
              u8: checkout?.totalPriceV2?.amount,
              ecommerce: {
                purchase: {
                  actionField: {
                    revenue: checkout?.totalPriceV2?.amount,
                    sub_total: checkout?.subtotalPriceV2?.amount,
                    tax: checkout?.totalTaxV2?.amount,
                    shipping: checkout?.shippingLine?.priceV2?.amount,
                  },
                  products: formattedItems,
                },
              },
              checkout: {
                country: checkout?.order?.shippingAddress?.country,
                state: checkout?.order?.shippingAddress?.province,
                zip: checkout?.order?.shippingAddress?.zip,
              },
            })

            const orderId = checkoutId.split("key=")[1]
            dataLayer.push({
              event: "purchase",
              currency: "USD",
              cost: parseFloat(checkout?.totalPriceV2?.amount!),
              ord: (orderId || checkoutId).replace(/[^a-z0-9]/gi, ""),
              transactionTotal: parseFloat(checkout?.totalPriceV2?.amount!),
              transactionShipping: parseFloat(
                checkout?.shippingLine?.priceV2?.amount!
              ),
              transactionTax: parseFloat(checkout?.totalTaxV2?.amount!),
              Country: checkout?.order?.shippingAddress?.country,
              State: checkout?.order?.shippingAddress?.province,
              ZIP: parseInt(checkout?.order?.shippingAddress?.zip!),
              Subtotal: parseFloat(checkout?.subtotalPriceV2?.amount),
              transactionProducts: JSON.stringify(formattedItems),
              u17: parseFloat(checkout?.shippingLine?.priceV2?.amount!),
              u16: parseFloat(checkout?.totalTaxV2?.amount!),
              u1: checkout?.order?.shippingAddress?.country,
              u2: checkout?.order?.shippingAddress?.province,
              u3: parseInt(checkout?.order?.shippingAddress?.zip!),
              u7: parseFloat(checkout?.subtotalPriceV2?.amount!),
              u13: JSON.stringify(formattedItems),
              u15: parseFloat(checkout?.totalPriceV2?.amount!),
              items: ga4items,
              ecommerce: {
                purchase: {
                  actionField: {
                    revenue: parseFloat(checkout?.totalPriceV2?.amount!),
                    sub_total: parseFloat(checkout?.subtotalPriceV2?.amount!),
                    tax: parseFloat(checkout?.totalTaxV2?.amount!),
                    shipping: parseFloat(
                      checkout?.shippingLine?.priceV2?.amount!
                    ),
                  },
                  products: formattedItems,
                },
              },
              checkout: {
                country: checkout?.order?.shippingAddress?.country,
                state: checkout?.order?.shippingAddress?.province,
                zip: parseInt(checkout?.order?.shippingAddress?.zip!),
              },
            })
          }
        }

        dispatch.checkout.clearCheckout()
      }
      dispatch.checkout.setCheckoutDataFetched(true)
    },

    async createCheckout(cartItems: Array<CartItem>) {
      const cartProducts = cartItems.reduce((acc, curr) => {
        acc[curr.sku] = curr
        return acc
      }, {})
      dispatch.checkout.updateLocalCheckoutItems(cartItems)
      dispatch.checkout.updateCartProducts(cartProducts)
      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: CreateCheckout,
        variables: {
          lineItems: cartItems.map(createCheckoutLineItem),
          note: CHECKOUT_NOTE,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      const checkoutErrors: Array<UserError> = getIn(
        data,
        "checkoutCreate.checkoutUserErrors",
        []
      )

      if (errors || (checkoutErrors && checkoutErrors.length)) {
        dispatch.checkout.setShowErrorMessage(true)
        dispatch.checkout.updateLocalCheckout({} as LocalCheckout)
        return
      }

      const checkout: CheckoutData = getIn(data, "checkoutCreate.checkout")
      dispatch.customer.associateCheckoutId(checkout.id)
      dispatch.checkout.setCheckoutId(checkout.id)
      dispatch.checkout.updateCheckout(checkout)
      dispatch.checkout.updateLocalCheckoutProps(
        getLocalCheckoutProps(checkout)
      )
    },

    // Updates localCheckout props and reconciles if needed
    async updateCheckoutItem(
      {
        checkoutId,
        cartItems,
      }: {
        checkoutId: string
        cartItems: Array<CartItem>
      },
      rootState
    ) {
      // console.log(cartItems)
      let variantIds: Array<string> = []

      cartItems.map((item) => {
        variantIds.push(item.shopifyVariantId)
        variantIds.push(btoa(item.shopifyVariantId))
      })

      // btoa(shopifyVariantId)
      // shopifyVariantId
      // )
      // console.log(variantIds)
      // console.log(getIn(rootState, "checkout.data.lineItems.edges", []))
      const [_, unchangedLineItems] = partitionLineItemsByVariantId(
        getIn(rootState, "checkout.data.lineItems.edges", []),
        variantIds
      )

      // console.log(partitionLineItemsByVariantId(
      //   getIn(rootState, "checkout.data.lineItems.edges", []),
      //   variantIds
      // ))

      let lineItems = unchangedLineItems

      cartItems.forEach((cartItem) => {
        if (cartItem.quantity > 0) {
          lineItems.push(createCheckoutLineItem(cartItem))
        }
      })

      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: UpdateCheckout,
        variables: {
          checkoutId,
          lineItems,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      const checkoutErrors: Array<UserError> = getIn(
        data,
        "checkoutLineItemsReplace.userErrors",
        []
      )

      if (errors || (checkoutErrors && checkoutErrors.length)) {
        dispatch.checkout.reconcileCheckout()
        return
      }

      const checkout: CheckoutData = getIn(
        data,
        "checkoutLineItemsReplace.checkout"
      )

      dispatch.checkout.updateCheckout(checkout)
      dispatch.checkout.updateLocalCheckoutProps(
        getLocalCheckoutProps(checkout)
      )
    },

    // On success, updates localCheckout props
    async updateDiscount(discountCode: string | undefined, rootState) {
      const client: DefaultClient<any> = createShopifyClient()
      const checkoutId = rootState.checkout.checkoutId

      if (discountCode) {
        dispatch.checkout.setLoadingCheckoutData(true)

        const { data, errors } = await graphQLRequest(client.mutate, {
          mutation: ApplyDiscount,
          variables: {
            checkoutId,
            discountCode,
          },
        })

        dispatch.checkout.setLoadingCheckoutData(false)

        const checkoutErrors: Array<UserError> = getIn(
          data,
          "checkoutDiscountCodeApplyV2.checkoutUserErrors",
          []
        )

        if (errors || (checkoutErrors && checkoutErrors.length)) {
          return getIn(checkoutErrors, "0", checkoutErrors)
        }

        const checkout: CheckoutData = getIn(
          data,
          "checkoutDiscountCodeApplyV2.checkout"
        )

        dispatch.checkout.updateCheckout(checkout)
        dispatch.checkout.updateLocalCheckoutProps(
          getLocalCheckoutProps(checkout)
        )

        const discountValue = getIn(
          checkout,
          "discountApplications.edges.0.node.value"
        )

        if (discountValue) {
          if (discountValue.percentage) {
            return { percentage: discountValue.percentage }
          }
          if (discountValue.amount) {
            return {
              amount: discountValue.amount,
              currencyCode: discountValue.currencyCode,
            }
          }
        }
      } else {
        dispatch.checkout.setLoadingCheckoutData(true)

        const { data, errors } = await graphQLRequest(client.mutate, {
          mutation: RemoveDiscount,
          variables: {
            checkoutId,
          },
        })

        dispatch.checkout.setLoadingCheckoutData(false)

        const checkoutErrors: Array<UserError> = getIn(
          data,
          "checkoutDiscountCodeRemove.checkoutUserErrors",
          []
        )

        if (errors || (checkoutErrors && checkoutErrors.length)) {
          return
        }

        const checkout: CheckoutData = getIn(
          data,
          "checkoutDiscountCodeRemove.checkout"
        )

        dispatch.checkout.updateCheckout(checkout)
        dispatch.checkout.updateLocalCheckoutProps(
          getLocalCheckoutProps(checkout)
        )
      }
    },

    // -> Product Details TODO UPSELL
    addToCart(
      {
        cartProduct,
        upsells,
      }: { cartProduct: CartProduct; upsells?: Array<CartProduct> },
      rootState
    ) {
      console.log(cartProduct)
      const productsToAdd = upsells ? [cartProduct, ...upsells] : [cartProduct]

      if (rootState.checkout.checkoutId) {
        const lineItems = getIn(rootState, "checkout.data.lineItems.edges", [])

        let updatedLocalCheckoutItems =
          rootState.checkout.localCheckout?.items || []
        let cartItems: Array<CartProduct> = []

        productsToAdd.forEach((product) => {
          const lineItem = getLineItemFromCheckoutData(
            // btoa(product.shopifyVariantId),
            product.shopifyVariantId,
            lineItems
          )

          if (!lineItem) {
            const lineItem = getLineItemFromCheckoutData(
              btoa(product.shopifyVariantId),
              lineItems
            )
          }

          updatedLocalCheckoutItems = lineItem
            ? updatedLocalCheckoutItems.map((item) => {
                if (item.shopifyVariantId === product.shopifyVariantId) {
                  return { ...item, quantity: item.quantity + product.quantity }
                }
                return item
              }) || []
            : [
                ...updatedLocalCheckoutItems,
                { ...product, quantity: product.quantity },
              ]

          const quantity = (lineItem && getIn(lineItem, "node.quantity")) || 0
          cartItems.push({ ...product, quantity: quantity + product.quantity })
        })

        dispatch.checkout.updateLocalCheckoutItems(updatedLocalCheckoutItems)
        dispatch.checkout.updateCheckoutItem({
          checkoutId: rootState.checkout.checkoutId,
          cartItems,
        })

        const cartProducts = productsToAdd.reduce((acc, curr) => {
          acc[curr.sku] = curr
          return acc
        }, {})

        dispatch.checkout.updateCartProducts({
          ...rootState.checkout.cartProducts,
          ...cartProducts,
        })
      } else {
        dispatch.checkout.createCheckout(productsToAdd)
      }

      dispatch.checkout.updateToastProduct(
        R.pick(
          ["productSlug", "productName", "image"],
          cartProduct
        ) as ToastProduct
      )

      dispatch.checkout.clearToastProduct()
    },

    // -> Cart HOC
    updateCart(cartItem: CartItem, rootState) {
      const updatedLocalCheckoutItems = R.pipe(
        R.map((item: CartItem) => {
          if (item.shopifyVariantId === cartItem.shopifyVariantId) {
            return cartItem.quantity === 0 ? undefined : cartItem
          }
          return item
        }),
        R.filter((item: CartItem | undefined) => !!item)
      )(rootState.checkout.localCheckout.items) as Array<CartItem>

      dispatch.checkout.updateLocalCheckoutItems(updatedLocalCheckoutItems)
      dispatch.checkout.updateCheckoutItem({
        checkoutId: rootState.checkout.checkoutId,
        cartItems: [cartItem],
      })
    },

    async clearToastProduct() {
      await new Promise((resolve) => {
        setTimeout(resolve, 5000)
      })

      dispatch.checkout.updateToastProduct(null)
    },

    // -> Cart HOC
    async clearShowErrorMessage() {
      await new Promise((resolve) => {
        setTimeout(resolve, 5000)
      })

      dispatch.checkout.setShowErrorMessage(false)
    },
  }),
})

export default checkout
